
.tn-events-detail-page {
	position: relative;
	.tn-prod-season-header {
		margin-bottom: 0;
		padding-bottom: 0;
    	border-bottom: none;
	}
	.tn-prod-season-header__date-container{
		display: none; // hide season level date range
	}
	.tn-prod-season-header__description-container {
		margin-top: 0;
		background-color: $white;
		padding: 10px;
	}
	.tn-detail-production-text-toggle-link {
		display: none;
	}
	.tn-event-detail__performance-details-container {
		width: 100%;
		@media (min-width: 768px) {
			width: 60%;
		}
		@media (min-width: 1000px) {
			width: 70%;
		}
	}
	.tn-event-detail__title {
		@extend .c-col-title;
	}
	.tn-event-detail__display-time {
		font-size: 110%;
		@extend .c-date;
		margin: 10px 0;
	}
	.tn-event-detail__location {
		@extend .c-location;
		margin: 10px 0;
	}
	.tn-ticketing-mode-change__content {
		font-style: normal;
		border-radius: 2px;
		padding: 0;
		background-color: transparent;
		border: 0;
		a {
			@extend .btn;
			@extend .btn-default;
		}
	}
	.tn-ticketing-mode-change {
		font-weight: bold;
	}
	.tn-ticketing-mode-change__anchor {
		border-bottom: 0;
		@media (max-width: $screen-xs-max) {
			font-size: 14px !important;
		}
	}
	.tn-syos__layout-container {
		border-width: 1px;
	}
	.tn-syos-screen-container__button-list-heading {
		color: $black;
	}
	.tn-syos-screen-container__button-list-container {
	    border-width: 0 1px 0 0;
	    background-color: $white;
	}
	.tn-syos-mobile-cart-controls__summary-subtotal {
		margin-bottom: 0;
		background-color: $white;
	}
	.tn-syos-screen-button {
		font-size: 90%;
		border-radius: 3px;
		background-color: $pink;
		border-color: inherit;
		transition: all 200ms ease;
		&:disabled {
			cursor: not-allowed;
			pointer-events: none;
		}
		&:hover:not([disabled]) {
			opacity: 0.9;
			transform: scale(1.05, 1.05);
		}
	}
	.tn-syos-seat-info__price-type--button {
		font-size: 80%;
		&:active {
			background-color: currentColor !important;
		}
	}
	.tn-syos-screen-button__section-heading {
		font-weight: bold;
	}
	.tn-syos-seat-info__header td {
		font-weight: bold;
	}
	.tn-syos__btn-add-to-cart {
		font-weight: bold;
		background-color: $pink;
		text-transform: initial;
	}
	.tn-event-detail__additional-events-container {
        font-size: 90%;
		@media (min-width: $screen-xs-max) {
			position: absolute;
			right: 0;
			margin-right: 0;
			background-color: transparent;
			top: 0;
		}
    }
    .tn-facebook-btn {
		border: none;
		float: right;
    }
	.tn-additional-events__instructions {
		border: none;
		background-color: transparent;
		font-size: 90%;
		opacity: 0.8;
	}

	.tn-additional-events {
		margin-bottom: 0;
	}

	.tn-syos-seat-info__seat-type {
		background-color: $brand-danger;
	}
	.tn-event-detail__title, .tn-event-detail__purchase-instructions {
		// @media (min-width: 768px) {
		// 	width: 60%;
		// }
		// @media (min-width: 1000px) {
		// 	width: 70%;
		// }
	}
}

.tn-events-detail-page .tn-additional-events__continue-shopping-link {
	display: none;
}

.tn-syos-mobile-cart-controls {
	@media (max-width: $screen-xs-max) {
		font-size: 11px;
	}
}
.tn-syos__btn-add-to-cart {
	@media (max-width: $screen-xs-max) {
		font-size: 11px;
	}
}

.tn-syos-screen-container__map-container img {
	@media (min-width: $screen-xs-max) {
		width: 95%;
		max-width: 1300px !important;
	}
}

.tn-ticket-selector__zone-selector {
	.tn-radio-button-list__button {
		color: #333333;
		background-color: #ffffff;
		border-color: #e6e6e6;
		&:hover {
			background: #e6e6e6;
		}
	}
	.tn-radio-button-list__input:checked+.tn-radio-button-list__button {
		background-color: $pink;
		border-color: $pink;
		color: #fff;
	}
}