.js-youtube__container {
    position:relative;
    padding-top:30px;
    height:0;
    overflow:hidden;
    padding-bottom:56.25%;
    // Specific to the media uploaded to the donate page
    @media only screen and (min-width: 600px) {
        height: 315px; //Max height of youtube video
        padding-bottom: inherit;
    }
    iframe, object, embed {
        position:absolute;
        width:100%;
        height:100%;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }
}

.donate {
    .tn-contribution-group-page {
        max-width: 960px;
        margin: 0 auto;
    }
    #tn-contribution-submit {
        position: absolute;
        bottom: -40px;
        right: 25px;
    }
    .tn-contribution-component {
        img {
            max-width: 100%;
        }
        .row {
            display: flex;
            justify-content: center;
            margin: 30px 0;
            padding-bottom: 30px;
        }
    }
}

.contribute2 {
    .tn-header-component {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }
    .tn-page-bottom {
        margin: 30px auto;
    }
    .col-sm-6 {
        width: 100%; 
    }
    .js-donate-button__container {
        display: flex;
        justify-content: flex-end;
    }
    // HEPD iframe
    .tn-contribution-type-form-container {
        @media only screen and (max-width: 600px) {
            iframe {
                width: 100% !important;
            }
        }
    }
}

.contribute1 {
    .col-sm-6 {
        width: 100%;
    }
    .tn-page-bottom {
        margin: 30px auto;
    }
}