
// subnav bar

.tn-subnav-component {
    padding: 8px 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    .tn-link, .tn-account-info-link, .tn-logout-link {
        display: inline-block !important;
        @extend .btn;
        @extend .btn-info;
        @extend .btn-sm;
        margin-right: 5px !important;
    }
    @media (max-width: $screen-sm-max) {
        .tn-logged-in-email {
            display: none !important;
        }
    }
    .tn-logout-link {
        @media (max-width: $screen-sm-max) {
            .tn-text {
                display: none !important;
            }
        }
        &:before {
            font-family: FontAwesome;
            content: "\f08b" !important;
            @media (max-width: $screen-sm-max) {
                font-size: 1.5em;
            }
            margin-left: 0 !important;
        }
    }
    .tn-login-link .tn-link {
        display: inline-block;
    }
    .tn-login-link {
        float: left;
        margin: 0;
    }
    .tn-cart-link {
        float: right;
        margin: 0;
    }
    .tn-login-link, .tn-cart-link .tn-link {
        @include inuit-hidden-visually();
        // position: relative !important;
    }
}

.account-create #tn-apply-promo {
    @include inuit-hidden-visually();
}

.c-tnew-wrap .tn-subnav-component {
    #tn-apply-promo {
        @include form-inline();
    }
    .tn-promo-box {
        @media (max-width: $screen-sm-max) {
            margin: 0;
        }
    }
    .tn-promo-applied:before {
        // Remove vertical bar
        display: none;
    }
    .tn-promo-box button.tn-subnav-promo-button {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-sm;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        text-decoration: none;
        font-size: 0 !important;
        &:after {
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: Glyphicons Halflings;
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            content: "\E080";
            font-size: 16px;
        }
    }
    .tn-subnav-promo-code {
        display: inline-block;
        max-width: 125px;
        @extend .form-control;
        height: 35px;
        padding: 5px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @media (max-width: $screen-sm-max) {
            position: relative;
            top: 1px;
        }
    }

}

@media (min-width: $screen-xs-max) {
	// .tn-timer {
	// 	.tn-timer-description {
	// 		float: left;
	// 	}
	//     position: absolute;
	// 	top: 42px;
	// 	right: 260px;
	//     width: 230px;
	//     display: block;
	//     background: #fff;
	//     padding: 10px;
	//     font-size: 87%;
	//     letter-spacing: 0.5px;
	//     border: 2px solid #dfe0ef;
	//     border-right: 0;
	// }
}

@media (max-width: 768px) {
    .tn-subnav-component .tn-cart-link .tn-timer {
        &:before {
            content: '\f017';
            color: $pink;
            font-family: FontAwesome;
            display: inline-block;
            margin-right: 5px;
        }
    }
}



.tn-syos-mobile-cart-controls__summary-subtotal {
    margin-bottom: 0;
}

.tn-syos-busy-indicator {
    @extend .animated;
    @extend .fadeInUp;
    .tn-syos-busy-indicator__message {
        font-style: normal;
    }
}


@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
