///* ========================================================================
//   #COLOURS
//   ======================================================================== */
// The list of colour variables used globally across the project


//
// Project Colours as variables
//
$primary: #171717;
$secondary: #FFFFFF;
$tertiary: #D8127D;
$highlight: #542537;
$off-white: #FDFDFD;
$pink: #D8127D;

//
// Project Colour as Array
//
$colors: (
    primary: #171717,
	secondary: #EBD077,
	tertiary: #D8127D,
	highlight: #542537,
    black: #171717,
    white: #FFFFFF,
    off-white: #FDFDFD,
    pink: #D8127D,
    transparent: transparent
);

//
// Colour Helper Function
//
@function color($key) { // use e.g. color(navy) to refer to color hex values
    @return map-get($colors, $key);
}

//
// White/Black
//
$white: #ffffff;
$black: #000000;
$off-black: #404040;

//
// Greys
//
$grey: #808080;
$grey--lightest: #e6e6e6;
$grey--lighter: #ECF0F1;
$grey--light: #ECF0F1;
$grey--dark: #4A4A4A;
$grey--darker: #666666;
$grey--darkest: #4d4d4d;

//
// Warnings
//
$warning: #D95B43;
$error: #D91E36;
$success: #54777B;


//
// Social Colours
//
$twitter: #00aced;
$facebook: #3b5998;
$linkedin: #007bb6;
$youtube: #bb0000;
$tumblr: #32506d;
$instagram: #517fa4;
$vimeo: #aad450;
$lastfm: #D51007;


//
// Background Colour Array
//

// use to apply complimentary text colours to each background
// used in 7-utlities/colour-classes

$backgrounds: (
    white: (
        background-color: color(white),
        color: color(black)
    ),
    dark: (
        background-color: color(black),
        color: color(white)
    ),
    pink: (
        background-color: color(pink),
        color: color(white)
    ),
    transparent: (
        background-color: color(transparent)
    )
);
