///* ========================================================================
//   #VENDOR
//   ======================================================================== */

/**
 * Create vendor-prefixed CSS in one go, e.g.
 *
   `@include vendor(border-radius, 4px);`
 *
 */
 @mixin vendor($property, $value...){
    -webkit-#{$property}:$value;
       -moz-#{$property}:$value;
        -ms-#{$property}:$value;
         -o-#{$property}:$value;
            #{$property}:$value;
}
