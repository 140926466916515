.tn-prod-listing {
    a {
        color: $text-color;
    }
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
    h3 {
        font-size: 100%;
    }
}

.tn-event-listing__primary-views-container {
    .tn-btn-datepicker__icon-container {
        color: $white;
    }
    .tn-event-listing-mode-tab-nav__list-item {
        color: $gray-dark;
    }
    .tn-events-calendar__list {
        margin: 0;
    }
    .tn-events-calendar__day-event-list {
        margin: 0;
    }
}

.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

#tn-events-calendar-view-month {
    .tn-events-calendar__day-cell {
        background-color: #fff;
    }
    .tn-events-calendar__day-cell--today {
        background-color: #eee;
    }
}
