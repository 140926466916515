body {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

.tn-heading-sub-text {
    a {
        text-decoration: underline;
    }
}

.c-cta {
    position: relative;
    display: inline;

    &::before {
        transition: 300ms all ease-in-out;
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
        transform: scaleY(0.5);
        transform-origin: top left;
        background-color: $pink;

        @include mq($from: tablet) {
            bottom: -10px;
        }
    }


    &::after {
        font-family: 'FontAwesome';
        content: '\f0da';
        margin-left: 15px;
    }

    &:hover {
        &::before {
            width: 80%;
        }
    }
    a {
        text-decoration: none;
    }
}

a.c-cta {
    text-decoration: none;
}

.c-page-title {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    color: $black;
}

h1.tn-heading {
    @extend .c-page-title;
}

.c-col-title {
    font-size: 200%;
    margin-bottom: 20px;
    position: relative;

    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 400;
    color: $black;
    &::after {
        transition: 300ms all ease-in-out;
        content: '';
        height: 2px;
        width: 30%;
        min-width: 50px;
        position: absolute;
        bottom: -10px;
        left: 0;
        transform: scaleX(0.8);
        transform-origin: top left;
        background-color: $pink;
    }
}

.c-location {
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 300;
    &:before {
        content: '\f041';
        color: $pink;
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 5px;
    }
}

.c-date {
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 300;
    &:before {
        content: '\f133';
        color: $pink;
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 5px;
    }
}

// Unhide admin editor button

.tn-admin-preview-banner {
    z-index: 9999;
    position: fixed;
    bottom: 0;
    .tn-admin-preview-banner__content {
        flex-direction: column;

    }
    .tn-admin-preview-banner__heading {
        font-size: 16px;
        margin: 0 0 10px 0;
    }
    .btn-group {
        display: flex;
        flex-direction: column;
        a {
            @extend .btn-sm;
            margin: 5px 0;
        }
    }
}