$header-height-mobile: 100px;
$header-height-tablet: 100px;
$header-height-desktop: 120px;
$header-full-width: 990px;

/* ==========================================================================
   #TNEW HEADER
   ========================================================================== */


$header-height-mobile: 100px;
$header-height-tablet: 100px;
$header-height-desktop: 120px;


.c-global-header-logo__link {
    display: block;
    width: 80px;
    height: auto;
    margin-top: 8px;
    border: none;
    @include mq($from: tablet) {
        width: 130px;
        height: 90px;
        margin-top: 15px;
    }
    img {
        max-width: 100%;
        height: auto;
        margin-left: 10px;
        @include mq($from: $header-full-width) {
            margin-left: 15px;
        }
    }
}

.c-global-header__back-link {
    position: absolute;
    @include mq($from: tablet) {
        top: 18px;
        right: 0;
    }
    @include mq($until: tablet) {
        right: 10px;
        top: 12px;
        font-size: 14px;
    }
    .c-btn {
        font-size: 60%;
        @include mq($from: tablet) {
            font-size: 90%;
        }
    }
}


/* ==========================================================================
   #GLOBAL HEADER styles
   ========================================================================== */

.c-global-header {
    width: 100%;
    background-color: #FDFDFD;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

.nav-open {
    .c-global-header--mobile {
        height: 100%;
    }
}

.c-global-header--desktop {
    display: none;
    height: $header-height-desktop;
    border-bottom: 1px solid $grey--lighter;

    @include mq($from: $header-full-width) {
        display: block;
    }
}

.c-company-logo {
    //background-image: url('../img/art-logo--black.svg');
    background-repeat: no-repeat;
    width: 130px;
    height: 90px;
    background-size: 100%;
    display: inline-block;
    margin-top: 15px;
    margin-left: 30px;
}

.c-global-header__rhs {
    position: absolute;
    right: 0;
    top: 0;
}

.c-global-header__top-bar {
    border-bottom: 1px solid $grey--lighter;
}

.c-secondary-nav {
    margin: 0;
    text-transform: uppercase;
}

.c-secondary-nav--desktop {
    padding: 6px 15px 0 0;
    font-size: 80%;
    display: inline-block;
}

.c-header-buttons {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0;
    padding: 0;
    list-style: none;
    background-color: transparent;

    li {
        line-height:30px;
        font-size: 140%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        color: $black;
        background-color: transparent;
        margin: 35px 5px 0;
        cursor: pointer;
        position: relative;


        @include mq($from: $header-full-width) {
            margin: 0 0 0 1px;
            font-size: 100%;
            &:last-child a { //replicate the cart icon from main site due to shared session
                padding-right: 15px;
            }
        }

        a {
            @include mq($from: $header-full-width) {
                background-color: $black;
                color: white;
                min-width: 30px;
                height: 30px;
                font-size: 80%;
                display: block;
                padding: 0 10px;
            }
        }
    }
}

.c-logged-in-message {
    display: none;
}

.logged-in {
    .account-button {
        padding: 0 5px;
    }

    .fa-user {

        @include mq($from: $header-full-width) {
            color: white;
            width: 30px;
            height: 30px;
            margin: 0 0 0 1px;
            font-size: 100%;
        }
    }

    .c-header-buttons {
        @include mq($until: $header-full-width) {
            margin-right: 10px;

            li {
                margin: 35px 5px 0;
            }
        }

    }

    .c-logged-in-message {
        display: inline-block;
        @include mq($from: $header-full-width) {
            margin-right: 5px;
        }

        @include mq($until: $header-full-width) {
            display: block;
            font-size: 8px;
            line-height: 13px;
        }
    }
}


.c-global-header__main-navigation {
    text-align: right;
}

.c-primary-nav {
    font-size: 160%;
    margin: 20px 0;
    padding-right: 50px;

    a {
        position: relative;

        &::after {
            //@include transition();
            content: '';
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: -10px;
            left: 0;
            transform: scaleY(0.5);
            transform-origin: top left;
            background-color: $pink;
        }

        &:hover, &.current {
            &::after {
                transform: scaleY(1);
            }
        }
    }
}

.c-global-header--mobile  {
    height: $header-height-mobile;
    border-bottom: 1px solid $grey--lighter;
    overflow: hidden;


    .c-global-header-logo__link {
        @include mq($until: tablet) {
            width: 105px;
            height: 80px;
            margin-top: 15px;
        }
    }

    @include mq($from: $header-full-width) {
        display: none;
    }
}

.c-primary-nav--mobile {
    text-align: center;
    display: block;
    padding: 0;
    margin: 30px 0 50px;

    &::after {
        display: none;
    }


    li {
        display: block;
        margin-bottom: 15px;
    }
}

.c-secondary-nav--mobile {
    text-align: center;
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin-bottom: 5px;
    }
}
