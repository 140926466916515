.tn-pre-cart-page {
    input.btnStyle {
        @extend .btn;
        @extend .btn-default;
    }
}
// Super Fragile - based upon the tables set up by ART/Tess
.tn-precart-response-message {
    button b {
    }
    tr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // Desktop
        @media (min-width: 768px) {
            flex-direction: row;
        }
        // Mobile
        @media (max-width: 768px) {
            td {
                width: 100%;
            }
        }
    }
    form {
        tr {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            // Desktop
            @media (min-width: 768px) {
                flex-direction: row;
                td:last-of-type {
                    flex-basis: 30%;
                }
            }
            // Mobile
            @media (max-width: 768px) {
                td {
                    width: 100%;
                }
            } 
        }
        .btnStyle {
            float: right;
        }
    }
    input[type="text"] {
        @extend .form-control;
    }
    select {
        @extend .form-control;
    }
}

// Special seat precart component
.tn-special-seat-notification-component {
    .col-sm-6 {
        width: 100%;
    }
    .tnc-button-block {
        .col-sm-6 {
            width: 50%;
        } 
    }
    #tn-special-seat-notification-component__btn-goback {
        @extend .btn-default;
    }
}
#TNEWCUSTOM-special-seat-component .tn-special-seat-notification-component table tr {
    display: table-row;
}
