
.tn-account-login-page {
	.tn-account-login-forgot-login-link {
		color: $black;
	}
}

.js-login__button-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    #tn-login-button {
        margin: 0 12px 12px 0;
    }
}

.tn-login-facebook-button {
    &:before {
        content: "\F230";
        font-family: FontAwesome;
        padding-right: .5em;   
    }
}

#tn-update-button {
    margin-top: 1em;
}
.tn-account-login-page {
    .tn-register-button {
        font-size: 12px;
    }
}

.tn-heading-sub-text {
    .c-btn {
        @extend .btn;
        @extend .btn-primary;
    }
}