/* ==========================================================================
   #GLOBAL FOOTER STYLES
   ========================================================================== */

.c-global-footer {
    background-color: $black;
    color: $white;
    padding: 5% 5% 20% 5%;
    width: 100%;
    position: relative;
    margin-top: $inuit-global-spacing-unit;
    clear: both;
    display: flex;   
    flex-direction: column;
    
    @include mq($from: tablet) {
        margin-top: $inuit-global-spacing-unit-large;
        flex-direction: row;
        justify-content: space-between;
        padding: 5% 5% 10% 5%;
    }

    @include mq($from: desktop) {
        padding: 5%;
    }
    a {
        color: $white;
    }
}

.c-global-footer__logo {
    height: 90px;
    width: 320px;
    max-width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    display: block;
}

.c-global-footer__logo--harvard {
    width: 180px;
    height: 50px;
    display: block;
    margin-bottom: 40px;
}

.c-global-footer__text {
    margin-top: 20px;

    p {
        font-size: 90%;
        line-height: 25px;
    }

    a {
        text-decoration: underline;
    }
}

.c-global-footer__lhs,
.c-global-footer__rhs {
    @include mq($from: tablet) {
        max-width: 50%;
    }
}

.c-global-footer__rhs {

    .c-global-footer__text {
        @include mq($from: tablet) {
            text-align: right;
        }
    }
}

.c-global-footer__signup {

    @include mq($from: tablet) {
        text-align: right;
    }

    a {
        display: inline-block;
        margin-top: 1.25em;
        padding: 10px 22px;
        border: 2px solid currentColor;
        border-radius: 4px;
        text-align: center;
    }
}

.c-footer-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
        text-decoration: underline;
    }

    @include mq($from: tablet) {
        text-align: right;
    }
}

.c-social-icons {
    margin: 0 0 10px 0;
    padding: 0;
    list-style-type: none;
    font-size: 160%;

    li {
        display: inline-block;
    }

    @include mq($from: tablet) {
        text-align: right;
        margin: 0 0 30px 0;

        li {
            margin-left: 10px;
        }
    }
}
