body.cart {
    font-family: "Gotham SSm A", "Gotham SSm B",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
}

.tn-cart-buttons__secondary-action {
    @extend .c-cta;
}

.tn-cart-item-summary__action-item--edit-package a {
    text-decoration: underline;
}
.tn-cart-component {
    .tn-cart-item-summary__property--name {
    }
    .tn-cart-item-summary__property--date-time {
        @extend .c-date;
    }
    .tn-cart-item-summary__property--location {
        @extend .c-location;  
    }
    .tn-cart-item-summary {
        color: $black;
    }
    .tn-cart-item-details__list {
        background-color: #EEE;
        color: $black;

    }
    .tn-cart-item__package-perfs-container {
        border: 0px solid #CCC;
        border-left-width: 1px;
    }
    .tn-cart-totals__line-item--primary {
        color: $black;
    }
    .tn-cart-item-detail__list {
        border-top-width: 0;
        li {
            margin-top: 0.5em;
        }
    }
}

.tn-cart-details-page {
    .tn-heading {
        font-size: 2em;
    }
}
.tn-payment-component {
    #tn-payment-submit-button {
        float: right;
        font-size: 16px !important;
        @media (min-width: 768px) {
            width: 400px;
        }
    }
}

.tn-order-address-information-component {
    .tn-edit-billing-link, .tn-edit-delivery-link {
        @extend .btn;
        @extend .btn-default;
        margin: 10px 0;
    }
}

// Donation pre-cart
.tn-donationAskCheckout-component {
    background-color: #EEE;
    border: 1px solid #CCC;
    padding: 5px;
    @media (min-width: 768px) {
        padding: 15px;
    }
    legend.tn-heading {
        font-size: 12px;
        font-weight: normal;
        img {
            max-width: 100%;
            height: auto;
        }
        a {
            text-decoration: underline;
        }
        @media (min-width: 768px) {
            font-size: 16px;
        }
        #OtherRadio input[type=number] {
            @extend .form-control;
        }
        .component-actions {
            button {
                margin: 10px 0;
            }
            .btn-default {
                @extend .btn-link;
            }
        }
    }
    .component-actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        button {
            @media (max-width: 768px) {
                margin: 15px 10px;
            }
            margin: 0 10px;
            // Make a button look like a cta
            &:first-of-type {
                @extend .c-cta;
                background: transparent;
                border: none;
                color: $black;
                padding: 0;
                text-transform: none;
                font-weight: normal;
                height: fit-content;
            }
        }
    }
}
