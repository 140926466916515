/* ==========================================================================
   #HAMBURGER
   ========================================================================== */

.c-global-header-hamburger {
    @include vendor(transform, translate(-50%, 0));
    position: absolute;
    top: 40px;
    left: 50%;
    height: 15px;
    z-index:1000;
    margin: 0;
    display: inline-block;
    vertical-align: top;   
}

.c-hamburger {
    width: 30px;  
    height: 100%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    @include vendor(transition, transform 300ms ease, background 0ms);
    cursor: pointer;
}
.c-hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $black;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    @include vendor(transition, transform 300ms ease, background 0ms);

    &:nth-child(1) {
        top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
        top: 6px;
    }
    &:nth-child(4) {
        top: 12px;
    }
}

.nav-open .c-hamburger  {
    span:nth-child(1) {
        top: 6px;
        width: 0%;
        left: 50%;
    }
    span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    span:nth-child(4) {
        top: 6px;
        width: 0%;
        left: 50%;
    }
}

.c-hamburger__label {
    font-size: 60%;
    position: absolute;
    padding-top: 25px;
    left: -1px;
    text-transform: uppercase;
}

.nav-open .c-hamburger__label--menu {
    display: none;
}

.c-hamburger__label--close {
    display: none;
    .nav-open & {
        display: block;
        left: -3px;
    }
}


