.tn-package-listing-page {
    .tn-prod-list-item {
        background-color: $white;
        border: $white;
    }
    .tn-prod-list-item__property--heading {
        @extend .c-col-title;
    }
    .tn-package-list-item__date-range {
        @extend .c-date;
    }
    .tn-package-list-item__location {
        @extend .c-location;
    }
}

.tn-flex-package-detail-page {
    .tn-flex-package-details__title {
        @extend .c-col-title;
    }
    .tn-flex-package-details__date-range {
        margin-top: 40px;
        @extend .c-date;
    }
    .tn-flex-package-details__locations-list {
        margin: 10px 0 0;
    }
    .tn-flex-package-details__locations-list-item {
        @extend .c-location;
    }
}

.tn-flex-layout__main-container {
    .tn-flex-package-details__title {
        @extend .c-col-title;
        margin-bottom: 40px;
    }
    .tn-flex-package-details__instructions {
        background-color: #EEE;
    }
    .tn-flex-performance-group__production-list, .tn-flex-performance-selector__form-group {
        background-color: $white;
    }
    .tn-flex-performance-selector__border {
        border: 1px solid #fff;
    }
    .tn-flex-performance-selector__form-group {
        border-bottom: 2px solid #fff;
    }
    .tn-flex-performance-group {
        border: 1px solid #CCC;
        }
    .tn-flex-performance-group__title {
        background-color: #EEE;
    }
    .tn-flex-performance-group__btn-toggle-productions {
        @media (min-width: 768px) {
            top: 4px;
        }
    }
}

.tn-flex-layout__sidebar-container {
    .tn-flex-cart__main-container {
        color: $black;
        background-color: #fff;
        border: 1px solid #CCC;
    }
    .tn-flex-cart__primary-heading {
        color: $black;
    }
    .tn-flex-cart__primary-heading {
        background-color: #fff;
    }
    .tn-flex-cart__primary-section {
        background-color: #fff;
    }
}

.tn-flex-layout__sidebar-container, .tn-flex-layout__main-container {
    .tn-flex-selection {
        background-color: $gray-lighter;
        border: 0;
    }
}

.tn-flex-package-in-progress-page {
    .tn-flex-in-progress-package__list-item {
        border: 1px solid #CCC;
    }
    .tn-flex-in-progress-package__header {
        background-color: #EEE;
    }
    .tn-flex-selection--in-progress {
        background-color: #EEE;
        border: 1px solid #CCC; 
    }
}

// Precart

.tn-precart-response-message {
    .tnew-eventlisting-prod-perf-date {
        text-transform: uppercase;
    }
}