///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here

@font-face {
  // font-family: 'Custom Font';
  // src: url('../fonts/custom.eot');
  // src: url('../fonts/custom.eot?#iefix') format('embedded-opentype'),
  //      url('../fonts/custom.woff2') format('woff2'),
  //      url('../fonts/custom.woff') format('woff');
  // font-weight:  400;
  // font-style:   italic;
  // font-stretch: normal;
}

@font-face {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: italic;
    font-weight: 700;
}
